export default [
  {
    path: '/Mood',
    name: 'Mood',
    component: () => import('@/views/EditData/pages/mood.vue'),
  },
  {
    path: '/Persona',
    name: 'Persona',
    component: () => import('@/views/EditData/pages/persona.vue'),
  },
  {
    path: '/Interests',
    name: 'Interests',
    component: () => import('@/views/EditData/pages/interests.vue'),
  },
];
