// 对axios进行二次封装
import axios from 'axios';

export const DEV = 'dev';
export const TEST = 'development';
export const PRODUCTION = 'production';

/**
 * -------------------
 * 这里的常量时给request({
 *  domain: 这里使用的
 * })
 * -------------------
 */
export const OLD_BASE_URL = 'OLD_BASE_URL';
// 商户
export const VENDOR = 'VENDOR';
//经济
export const ECONMIC = 'ECONMIC';
// 用户
export const USER = 'USER';
// 如果有新增可以在这里增加
/** end */

export const URLS = {
  [DEV]: {
    // 原有的链接
    [OLD_BASE_URL]: 'https://dev.qdbbyp.cn/',
    // 商户系统的
    [VENDOR]: 'http://merchant-dev.qdbbyp.cn',
    //经济系统的
    [ECONMIC]: 'http://finance-test.qdbbyp.cn',
    //用户系统的
    [USER]: 'https://user-test.qdbbyp.cn',
  },
  [TEST]: {
    // 原有的链接
    [OLD_BASE_URL]: 'https://test.qdbbyp.cn/',
    // 商户系统的
    [VENDOR]: 'https://merchant-test.qdbbyp.cn',
    //经济系统的
    [ECONMIC]: 'http://finance-test.qdbbyp.cn',
    //用户系统的
    [USER]: 'https://user-test.qdbbyp.cn',
  },
  [PRODUCTION]: {
    // 原有的链接
    [OLD_BASE_URL]: 'https://yf.qdbbyp.com/',
    // 商户系统的
    [VENDOR]: 'https://merchant.qdbbyp.com',
    //经济系统的
    [ECONMIC]: 'https://finance.qdbbyp.com',
    //用户系统的
    [USER]: 'https://user.qdbbyp.com',
  },
};

let baseURL = 'https://yf.qdbbyp.com/';
// let baseURL = 'https://merchant-test.qdbbyp.cn/'   //商户dev
// let baseURL = 'http://finance-dev.qdbbyp.cn'     //经济域名dev
const baseURLS = URLS[process.env.NODE_ENV] || URLS[PRODUCTION]
// const baseURLS = URLS[process.env.NODE_ENV] || URLS[TEST];

// 1. 利用axios对象的方法create，去创建一个axios案例
// 2. requests就是axios
const requests = axios.create({
  baseURL,
  timeout: 5000, //请求超时的时间5s
});

// 请求拦截器：在发送请求之前，请求拦截器可以检测到，在请求发送之前处理一些事情
requests.interceptors.request.use((config) => {
  // // 如果config的domain存在, 则查找对应baseURL
  if (config.domain) {
    let baseURL = baseURLS[config.domain];
    // 如果没有填写domain那么就直接使用之前的url
    if (!baseURL) baseURL = baseURLS[OLD_BASE_URL];
    config.baseURL = baseURLS[config.domain];
  }

  // console.log("请求拦截器");
  // 为请求头添加Authorization字段为服务端返回的token
  const token = localStorage.getItem('token');
  // config.headers["access_token"] = token
  config.headers['Authorization'] = `Bearer ${token}`;

  // 在请求中添加 Authorization 头部
  // config.headers['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjM2NDI1LCJpc3MiOiJhcHAiLCJleHAiOjE3MjM2MzU0OTUsIm5iZiI6MTcyMTA0MzQ5NSwiaWF0IjoxNzIxMDQzNDk1fQ.GfcD1HvdR9b7esy7npKAzeSVABxyyyDOjSAP0bllVrI` // 请求头带上token

  return config;
});

// 响应拦截器：
requests.interceptors.response.use(
  (res) => {
    // console.log("响应拦截器");
    // 成功的回调函数
    return res.data;
  },
  (error) => {
    // 失败的回调函数
    return Promise.reject(new Error('faile'));
  }
);

// 对外暴露
export default requests;

