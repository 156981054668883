import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// 全局引入bridge
import Bridge from './config/bridge.js';
Vue.prototype.$bridge = Bridge;
// 引入axios
import Axios from 'axios';
Vue.prototype.$axios = Axios;

// import * as from '../src/api/index.js';
// Vue.prototype.$api = api
import * as api from '@/api/index.js';
Vue.prototype.$api = api;

//全局引入Vant
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
//全局引入弹窗
import { Toast } from 'vant';
Vue.use(Toast);
//引入vant中的Area
import { Area } from 'vant';
Vue.use(Area);
// import { Toast } from 'vant';
// Vue.use(Toast);

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

import * as uni from '@/assets/uni.webview.1.5.5.js';
document.addEventListener('UniAppJSBridgeReady', function (e) {
  console.log(e);
  Vue.prototype.myUni = uni;
});


// import wx from '@/assets/js/jweixin-1.4.0.js';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
