export const SET_MOOD = 'SET_MOOD';
export const SET_PERSONA = 'SET_PERSONA';
export const SET_INTERESTS = 'SET_INTER';

export default {
  state: {
    mood: [],
    persona: [],
    interests: [],
  },
  mutations: {
    [SET_MOOD]: (state, mood) => {
      state.mood = mood;
    },
    [SET_PERSONA]: (state, persona) => {
      state.persona = persona;
    },
    [SET_INTERESTS]: (state, interests) => {
      state.interests = interests;
    },
  },
  actions: {
    [SET_MOOD]: ({ commit }, mood) => {
      commit(SET_MOOD, mood);
    },
    [SET_PERSONA]: ({ commit }, persona) => {
      commit(SET_PERSONA, persona);
    },
    [SET_INTERESTS]: ({ commit }, interests) => {
      commit(SET_INTERESTS, interests);
    },
  },
};
