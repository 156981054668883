import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import EditData from './modules/editData';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: '', // pq0G0fE_CSFwktv3cNZuYzzHDGpYZfZ1
    value: '', // name
    idiograph: '', //个性签名
  },
  getters: {},
  mutations: {
    SetToken(state, Newtoken) {
      state.token = Newtoken;
    },
    updateValue(state, payload) {
      state.value = payload;
    },
    updateidiograph(state, payload) {
      state.idiograph = payload;
    },
  },
  actions: {
    setValue({ commit }, value) {
      commit('updateValue', value);
    },
    setidiograph({ commit }, idiograph) {
      commit('updateidiograph', idiograph);
    },
  },
  modules: {
    EditData,
  },
  plugins: [persistedState()],
});
