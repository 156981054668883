//引入
import requests from './request';

// 获取商家信息
export const getbusinfo = (data) =>
  requests({
    url: '/bfb/index.php/api/Transfer/mch_insert',
    method: 'post',
    params: data,
  });

export const getbusinfoLeiMu = (data) =>
  requests({
    url: 'https://yf.qdbbyp.com/web/index.php?_platform=ios&store_id=1&r=api/mch/index/apply&access_token=KBgU5Znjuhaa092MHvGbRYvMJzYtkujf',
    method: 'post',
    params: data,
  });
//个人主页
export const getMyPage = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//个人主页 关于他接口
export const getMyPageHer = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
// //提交关注
// export const geTattention=(data)=>requests({
//     url:"https://yf.qdbbyp.com/web/index.php?_platform=wx&store_id=1&r=mobile/user/follow-add",
//     method:"post",
//     params:data
// })
// //取消关注
// export const geCloseTattention=(data)=>requests({
//     url:"/web/index.php",
//     method:"post",
//     params:data
// })
//Ta的分享
export const GetHerTattention = (data) =>
  requests({
    url: '/web/index.php',
    method: 'post',
    params: data,
  });
//Ta发起活动
export const GetHerTattentionThree = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//个人信息
// export const getMyPageList=(data)=>requests({
//     url:"/web/index.php",
//     method:"get",
//     params:data
// })
//获取所有省
export const getProvince = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//获取商户编辑资料
export const getEditPage = (data) =>
  requests({
    url: '/web/index.php',
    method: 'post',
    params: data,
  });
//保存商户编辑资料
export const getEditPageSave = (data) =>
  requests({
    url: '/web/index.php',
    method: 'post',
    params: data,
  });
//门店展示
export const GetStore = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });

//门店管理
export const GetStoreManagementPage = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//门店管理商家数据
export const GetStoreManagementPageNum = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//门店管理商家商品
export const GetStoreManagementPageShop = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//门店管理 关店开店状态
export const GetStoreeShopChange = (data) =>
  requests({
    url: '/web/index.php',
    method: 'post',
    params: data,
  });
//门店订单
export const GetStoreOrder = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//活动详情
export const Getdetailactivity = (data) =>
  requests({
    url: '/web/index.php',
    method: 'post',
    params: data,
  });
//核销订单
export const GetCancel = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//待签到的
export const GetdetailSinder = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//核销商品
export const GetCancelOver = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//粉丝
export const GetFans = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//关注
export const GetAttention = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//上下架商品
export const GetGoodsShopStatus = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//账户余额
export const GetMoney = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//Banner跳转优质商家页面
export const GetBannerShop = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });
//编辑资料 标签
export const Gettag = (data) =>
  requests({
    url: '/web/index.php',
    method: 'get',
    params: data,
  });

//编辑资料保存
export const editApi = (data) =>
  requests({
    url: 'web/index.php?_platform=wx&store_id=1&r=api/user/upt-user-info',
    method: 'post',
    params: data,
  });
//参与者活动接口
export const detailactivityApi = (data) =>
  requests({
    url: 'web/index.php?_platform=wx&store_id=1&r=mobile/posts/my-apply-order-info',
    method: 'post',
    params: data,
  });
//参与者活动 取消约会 接口
export const detailactivityOverApi = (data) =>
  requests({
    url: 'web/index.php?_platform=wx&store_id=1&r=api/posts/remove-order',
    method: 'post',
    params: data,
  });
// //申请 金额页面接口
// export const tijiaoApi = (data)=>requests({
//     url:'web/index.php?store_id=1&r=api/mch/user/cash',
//     method:'post',
//     params:data
// })
//门店管理 开关接口
// export const ChangeApi = (data)=>requests({
//     url:'web/index.php?_platform=ios&store_id=1&r=api/mch/user/setting-open',
//     method:'post',
//     params:data
// })
//门店管理 打开银行卡 接口
export const GetBankApi = (data) =>
  requests({
    url: 'web/index.php?_platform=ios&store_id=1&r=api/user-wallet/get-user-bank',
    method: 'post',
    params: data,
  });




  //新接口
export const GetStorenew = (data) =>
  requests({
     url: '/v1/api/merchant/store/detail',
     method: 'get',
     params: data,
 }); 

 //审核页面
export const GetStoreCheck = (data) =>
  requests({
     url: '/v1/api/merchant/application',
     method: 'get',
     params: data,
 });
//商户开店关店
export const GetStorestatus = (data) =>
  requests({
     url: '/v1/api/merchant/store/status',
     method: 'post',
     params: data,
 });
//申请商户id
export const GetcommerID = (data) =>
  requests({
     url: '/v1/api/merchant/application/id',
     method: 'get',
     params: data,
 });
//所售类目
export const Getcommerleimu = (data) =>
  requests({
     url: '/v1/api/merchant/scene/category/list',
     method: 'get',
     params: data,
 });
//推荐类型
export const Getreferencetype = (data) =>
  requests({
     url: '/v1/api/merchant/application/reference-type',
     method: 'get',
     params: data,
 });
//商家财务中心
export const GetStorePage = (data) =>
  requests({
     url: '/v1/mch/finance/center',
     method: 'get',
     params: data,
 });
//银行卡数据回显
export const Getwallet = (data) =>
  requests({
     url: '/v1/wallet/get-wallet',
     method: 'get',
     params: data,
 });
//收支记录
export const Gettransactions = (data) =>
  requests({
     url: '/v1/mch/assets/transactions',
     method: 'get',
     params: data,
 });
//订单核销详情
export const GetverifyDetail = (data) =>
  requests({
     url: '/v1/orders/verify_detail',
     method: 'get',
     params: data,
 });
//资产
export const Getassets = (data) =>
  requests({
     url: '/v1/mch/assets',
     method: 'get',
     params: data,
 });
//提现列表
export const GetwithdrawList = (data) =>
  requests({
     url: '/admin/mch/withdraw/list',
     method: 'get',
     params: data, 
 });
//活动详情
export const GetInfoList = (data) =>
  requests({
     url: '/v1/posts/info',
     method: 'get',
     params: data,
 });
//报名列表
export const GetjoinUserList = (data) =>
  requests({
     url: '/v1/posts/join_user/list',
     method: 'get',
     params: data,
 });







