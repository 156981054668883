//判断机型
let u = navigator.userAgent;


function setupWebViewJavascriptBridge(callback) {
  //var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  //判断ios 还是Android

  // 判断是否在小程序环境中
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //在微信中
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge)
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback)
      }
      window.WVJBCallbacks = [callback]
      let WVJBIframe = document.createElement('iframe')
      WVJBIframe.style.display = 'none'
      document.documentElement.appendChild(WVJBIframe)
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe)
      }, 0)
    }
  } else {
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge)
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback)
      }
      window.WVJBCallbacks = [callback]
      let WVJBIframe = document.createElement('iframe')
      WVJBIframe.style.display = 'none'
      WVJBIframe.src = 'https://__bridge_loaded__'
      document.documentElement.appendChild(WVJBIframe)
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe)
      }, 0)
    }
  }
}

//安卓注册事件监听
function connectWebViewJavascriptBridge(callback) {
  if (window.WebViewJavascriptBridge) {
    callback(WebViewJavascriptBridge)
  } else {
    document.addEventListener(
      'WebViewJavascriptBridgeReady',
      function () {
        callback(WebViewJavascriptBridge)
      },
      false
    );
  }
}

connectWebViewJavascriptBridge(function (bridge) {
  //初始化
  if (!/(iPhone|iPad|iPod|iOS)/i.test(u)) {
    console.log("初始化")
    bridge.init(function (message, responseCallback) {
      //var data = {'Javascript Responds': 'Wee!'};
      responseCallback(data);
    });
  }
});

export default {
  callHandler(name, data, callback) {
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      setupWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler(name, data, callback)
      })
    } else {
      connectWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler(name, data, callback)
      })
    }
  },
  registerhandler(name, callback) {
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      setupWebViewJavascriptBridge(function (bridge) {
        bridge.registerHandler(name, function (data, responseCallback) {
          callback(data, responseCallback)
        })
      })
    } else {
      connectWebViewJavascriptBridge(function (bridge) {
        bridge.registerHandler(name, function (data, responseCallback) {
          callback(data, responseCallback)
        })
      })
    }
  }
}


