import Vue from 'vue';
import VueRouter from 'vue-router';
import EditDataRouter from './modules/editData';
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/OrderAmount',
    name: 'OrderAmount',
    component: () => import('../views/Order/OrderAmount.vue'),
  },
  {
    path: '/commercialLive',
    name: 'commercialLive',
    component: () => import('../views/commercial/commercialLive.vue'),
  },
  {
    path: '/MyPage',
    name: 'MyPage',
    component: () => import('../views/My/MyPage.vue'),
  },
  {
    path: '/EditPage',
    name: 'EditPage',
    component: () => import('../views/EditData/EditPage.vue'),
  },
  {
    path: '/Edit',
    name: 'Edit',
    component: () => import('../views/Edit/Edit.vue'),
  },
  {
    path: '/relese',
    name: 'relese',
    component: () => import('../views/Relent/relese.vue'),
  },
  {
    path: '/StoreDisplay',
    name: 'StoreDisplay',
    component: () => import('../views/Store/StoreDisplay.vue'),
  },
  {
    path: '/StoreManagementPage',
    name: 'StoreManagement',
    component: () => import('../views/StoreManagement/StoreManagementPage.vue'),
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('../views/StoreOrder/Order.vue'),
  },
  {
    path: '/detailactivity',
    name: 'detailactivity',
    component: () => import('../views/DetailActivity/detailactivity.vue'),
  },
  {
    path: '/CancelOder',
    name: 'CancelOder',
    component: () => import('../views/CancelOder/CancelOder.vue'),
  },
  {
    path: '/sinder',
    name: 'sinder',
    component: () => import('../views/sinder/sinder.vue'),
  },
  {
    path: '/FansAndAttention',
    name: 'FansAndAttention',
    component: () => import('../views/FansAndAttention/FansAndAttention.vue'),
  },
  {
    path: '/RegistrationList',
    name: 'RegistrationList',
    component: () => import('../views/RegistrationList/RegistrationList.vue'),
  },
  {
    path: '/ServiceProtocol',
    name: 'ServiceProtocol',
    component: () => import('../views/ServiceProtocol/ServiceProtocol.vue'),
  },
  {
    path: '/WithdrawDeposit',
    name: 'WithdrawDeposit',
    component: () => import('../views/WithdrawDeposit/WithdrawDeposit.vue'),
  },
  {
    path: '/WithdrawalList',
    name: 'WithdrawalList',
    component: () => import('../views/WithdrawalList/WithdrawalList.vue'),
  },
  {
    path: '/IncomeandExpenses',
    name: 'IncomeandExpenses',
    component: () => import('../views/IncomeandExpenses/IncomeandExpenses.vue'),
  },
  {
    path: '/BestStore',
    name: 'BestStore',
    component: () => import('../views/BestStore/BestStore.vue'),
  },
  {
    path: '/DragonBoatFestival',
    name: 'DragonBoatFestival',
    component: () =>
      import('../views/DragonBoatFestival/DragonBoatFestival.vue'),
  },
  {
    path: '/College',
    name: 'College',
    component: () => import('../views/College/College.vue'),
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('../views/PrivacyPolicy/PrivacyPolicy.vue'),
  },
  {
    path: '/Version',
    name: 'Version',
    component: () => import('../views/Version/Version.vue'),
  },
  {
    path: '/Disclaimer',
    name: 'Disclaimer',
    component: () => import('../views/Disclaimer/Disclaimer.vue'),
  },
  {
    path: '/poster',
    name: 'poster',
    component: () => import('../views/poster/poster.vue'),
  },
  {
    path: '/College',
    name: 'College',
    component: () => import('../views/College/College.vue'),
  },
  {
    path: '/DragonBoatFestival',
    name: 'DragonBoatFestival',
    component: () =>
      import('../views/DragonBoatFestival/DragonBoatFestival.vue'),
  },
  {
    path: '/DDD',
    name: 'DDD',
    component: () => import('../views/DDD/DDD.vue'),
  },
  {
    path: '/Name',
    name: 'Name',
    component: () => import('../views/EditPageSmall/Name.vue'),
  },
  {
    path: '/Birth',
    name: 'Birth',
    component: () => import('../views/EditPageSmall/Birth.vue'),
  },
  {
    path: '/Heighting',
    name: 'Heighting',
    component: () => import('../views/EditPageSmall/Heighting.vue'),
  },
  {
    path: '/Schooling',
    name: 'Schooling',
    component: () => import('../views/EditPageSmall/Schooling.vue'),
  },
  {
    path: '/Gradtimeing',
    name: 'Gradtimeing',
    component: () => import('../views/EditPageSmall/Gradtimeing.vue'),
  },
  {
    path: '/Educationing',
    name: 'Educationing',
    component: () => import('../views/EditPageSmall/Educationing.vue'),
  },
  {
    path: '/Myimage',
    name: 'Myimage',
    component: () => import('../views/EditPageSmall/Myimage.vue'),
  },
  {
    path: '/H_provinceing',
    name: 'H_provinceing',
    component: () => import('../views/EditPageSmall/H_provinceing.vue'),
  },
  {
    path: '/Name',
    name: 'Name',
    component: () => import('../views/EditPageSmall/Name.vue'),
  },
  {
    path: '/StoreCheck',
    name: 'StoreCheck',
    component: () => import('../views/StoreCheck/StoreCheck.vue'),
  },
  {
    path: '/FriendlyReminder',
    name: 'FriendlyReminder',
    component: () => import('../views/FriendlyReminder/FriendlyReminder.vue'),
  },
  {
    path: '/BilliardsGame',
    name: 'BilliardsGame',
    component: () => import('../views/BilliardsGame/BilliardsGame.vue'),
  },
  {
    path: '/yuefan',
    name: 'yuefan',
    component: () => import('../views/yuefan/yuefan.vue'),
  },
  {
    path: '/party',
    name: 'party',
    component: () => import('../views/Party/party.vue'),
  },
  {
    path: '/WXActivity',
    name: 'WXActivity',
    component: () => import('../views/WXActivity/WXActivity.vue'),
  },
  {
    path: '/MerchantWillMove',
    name: 'MerchantWillMove',
    component: () => import('../views/MerchantWillMove/MerchantWillMove.vue'),
  },
  {
    path: '/ReportPage',
    name: 'ReportPage',
    component: () => import('../views/ReportPage/ReportPage.vue'),
  },
  {
    path: '/RuleCause',
    name: 'RuleCause',
    component: () => import('../views/RuleCause/RuleCause.vue'),
  },
  {
    path: '/myVip',
    name: 'MyVip',
    component: () => import('../views/MyVip/MyVip.vue'),
  },
  {
    path: '/boundTicket',
    name: 'BoundTicket',
    component: () => import('../views/MyVip/BoundTicket.vue'),
  },
  {
    path: '/Explorer',
    name: 'Explorer',
    component: () => import('../views/Explorer/Explorer.vue'),
  },
];

const router = new VueRouter({
  routes: routes.concat(EditDataRouter),
});

export default router;
